/* eslint-disable react/jsx-one-expression-per-line */
import { Highlight, connectRefinementList } from 'react-instantsearch-dom';
import styled from 'styled-components';
import orderBy from 'lodash/orderBy';
import { CheckMark } from '../../vectors/Misc';

const Wrapper = styled.section`
  display: grid;
  row-gap: var(--gap-6);
`;

const CheckboxList = styled.ul`
  list-style-type: none;
  display: grid;
  grid-auto-flow: row;
  row-gap: var(--gap-6);
`;

const FilterTitle = styled.h1`
  font-size: var(--font-size);
  color: var(--blue-2);
`;

const FakeCheckboxButton = styled.button`
  background: none;
  border: none;
  color: var(--blue-2);
  display: grid;
  grid-template-columns: 30px 1fr;
  padding: 4px 0;
  align-items: center;
  text-align: left;
`;

const Checkbox = styled.div`
  width: 20px;
  height: 20px;
  transition: background 200ms ease-in;
  background: ${({ isRefined }) => (isRefined ? 'var(--gold-2)' : 'none')};
  border: 2px solid var(--gold-2);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 12px;
  }
`;

const FacetText = styled.span``;

const Count = styled.span`
  color: var(--gold-2);
  font-weight: 500;
  margin-left: var(--gap-7);
`;

const RefinementList = ({
  items,
  isFromSearch,
  refine,
  filterTitle,
  scrollOnRefine,
}) => {
  const sortedItems = orderBy(items, 'label', 'desc');
  if (sortedItems.length === 0) return null;
  return (
    sortedItems.length >= 1 && (
      <Wrapper>
        <FilterTitle>{filterTitle}</FilterTitle>
        <CheckboxList>
          {sortedItems.map((item) => (
            <li key={item.label}>
              <FakeCheckboxButton
                type="button"
                onClick={() => {
                  refine(item.value);
                  scrollOnRefine();
                }}
              >
                <Checkbox isRefined={item.isRefined}>
                  <CheckMark />
                </Checkbox>
                <FacetText>
                  {isFromSearch ? (
                    <Highlight attribute="label" hit={item} />
                  ) : (
                    item.label
                  )}
                  <Count>({item.count})</Count>
                </FacetText>
              </FakeCheckboxButton>
            </li>
          ))}
        </CheckboxList>
      </Wrapper>
    )
  );
};

export const ProductsRefinementList = connectRefinementList(RefinementList);
