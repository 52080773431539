import { Link } from 'gatsby';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import { usePageLocale } from '../../hooks/usePageLocale';
import { ResultsCounter } from '../algolia/products/ProductsResultsCounter';
import { useProductsSlug } from '../../hooks/useProductsSlug';
import { useDefaultLocale } from '../../hooks/useDefaultLocale';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  padding: var(--gap-2) var(--padding) var(--padding) var(--padding);

  @media screen and (max-width: 640px) {
    padding: var(--padding);
  }
`;

const Container = styled.div`
  width: 1280px;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: var(--gap-6);
  column-gap: var(--gap-3);
  overflow-x: auto;
`;

const Nav = styled.nav`
  display: inline-grid;
  width: min-content;
  grid-auto-flow: column;
  column-gap: var(--gap-7);

  & a {
    white-space: nowrap;
    color: var(--blue-2);

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  & span {
    color: var(--gold-2);
    font-weight: 700;
    white-space: nowrap;
  }
`;

export const FakeBreadCrumb = ({ categoryTitle, breadcrumbRef }) => {
  const { locale } = usePageLocale();
  const { defaultLocale } = useDefaultLocale();
  const { slug } = useProductsSlug(locale);
  const intl = useIntl();

  return (
    <Wrapper ref={breadcrumbRef}>
      <Container>
        <Nav>
          <Link to={locale === defaultLocale ? `/${slug}` : `/${locale}/${slug}`}>
            {intl.formatMessage({ id: 'products' })}
          </Link>
          <span>{'  /  '}</span>
          <span>{categoryTitle}</span>
        </Nav>
        <ResultsCounter />
      </Container>
    </Wrapper>
  );
};
