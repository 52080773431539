import styled from 'styled-components';
import { connectCurrentRefinements } from 'react-instantsearch-dom';
import { useIntl } from 'react-intl';
import { CloseIcon } from '../../vectors/Misc';
import { ResetIcon } from '../../vectors/Reset';
import { usePageLocale } from '../../../hooks/usePageLocale';
import { t } from '../../../functions/renderTranslation';

const ClearButton = styled.button`
  text-decoration: underline;
  color: var(--gold-2);
  padding: var(--gap-7) 0;
  visibility: ${({ items }) => items.length < 1 && 'hidden'};
  border: none;
  margin: 0;
  background: none;
  width: min-content;
  white-space: nowrap;
  font-weight: 700;
  display: grid;
  column-gap: var(--gap-7);
  grid-auto-flow: column;
  align-items: center;
  font-size: var(--font-size-micro);

  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: var(--gold-2);
    }
  }
`;

const CloseButton = styled.button`
  border: none;
  margin: 0;
  background: none;
  width: 30px;
  padding: 0;

  @media screen and (min-width: 641px) {
    display: none;
  }

  & svg {
    width: 20px;
    height: 20px;

    & path {
      fill: var(--gold-2);
    }
  }
`;

const ClearRefinements = ({
  items,
  refine,
  scrollOnRefine,
  setShowFilters,
}) => {
  const intl = useIntl();

  return (
    <ClearButton
      items={items}
      type="button"
      onClick={() => {
        refine(items);
        scrollOnRefine();
        setShowFilters(false);
      }}
    >
      <ResetIcon />
      {intl.formatMessage({ id: 'resetFilter' })}
    </ClearButton>
  );
};

export const CloseRefinements = ({ onClick }) => (
  <CloseButton type="button" onClick={onClick}>
    <CloseIcon />
  </CloseButton>
);

export const CustomClearRefinements =
  connectCurrentRefinements(ClearRefinements);
