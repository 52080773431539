import { GatsbyImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { HeadingSection, SubtitleSection } from '../../styles/Typography';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 680px) {
    flex-direction: column;
  }
  overflow: hidden;
`;

const SectionBackground = styled(GatsbyImage)`
  width: 100%;
  height: 700px;

  @media screen and (max-width: 768px) {
    height: 580px;
  }

  @media screen and (max-width: 480px) {
    height: 450px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 1280px;
  position: absolute;
  z-index: 1;
  align-items: flex-end;

  @media screen and (max-width: 1280px) {
    width: 100%;
    padding: var(--padding);
  }

  @media screen and (max-width: 680px) {
    position: relative;
    align-items: unset;
  }
`;

const TextBoxWrapper = styled.div`
  display: flex;
  position: relative;
  width: min-content;
`;

const Illustration = styled.img`
  width: 130px;
  height: 130px;
  border-radius: 130px;
  position: relative;
  z-index: 2;
  top: 50px;
  background: var(--gold-2);

  @media screen and (max-width: 680px) {
    display: none;
  }
`;

const RoundedTextBox = styled.div`
  background: var(--blue-2);
  border-radius: 100%;
  display: grid;
  row-gap: var(--gap-5);
  width: 500px;
  height: 500px;
  justify-items: start;
  align-content: center;
  padding: 80px;
  position: relative;
  left: -8%;

  @media screen and (max-width: 680px) {
    width: 100vw;
    height: unset;
    margin-top: -50vw;
    padding: 40px 20px;
    left: -20px;
    border-radius: 0;
  }
`;

export const CategoryHero = ({
  backgroundImageData,
  categoryTitle,
  categoryDescription,
  illustrationUrl,
}) => {
  return (
    <>
      <Wrapper>
        <SectionBackground image={backgroundImageData} alt="" />
        <Container>
          <TextBoxWrapper>
            <Illustration
              alt={categoryTitle}
              width={130}
              height={130}
              src={illustrationUrl}
            />
            <RoundedTextBox>
              <HeadingSection small>{categoryTitle}</HeadingSection>
              <SubtitleSection css={{ color: 'white', maxWidth: 'unset' }}>
                {categoryDescription}
              </SubtitleSection>
            </RoundedTextBox>
          </TextBoxWrapper>
        </Container>
      </Wrapper>
    </>
  );
};
