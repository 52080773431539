/* eslint-disable react/jsx-wrap-multilines */
import { useRef } from 'react';
import { graphql } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import smoothscroll from 'smoothscroll-polyfill';
import { PageWrapper } from '../components/layout/PageWrapper';
import { CategoryHero } from '../components/layout/sections/CategoryHero';
import { FakeBreadCrumb } from '../components/product/FakeBreadCrumb';
import { ProductsPanel } from '../components/algolia/products/ProductsPanel';
import { isSSR } from '../utils/misc';
import { CategoriesStream } from '../components/layout/sections/CategoriesStream';
import { PRODUCTS_INDEX_NAME } from '../constants/algolia';

const CategoryTemplate = ({
  data: {
    category: {
      heroImage: { gatsbyImageData },
      title,
      description,
      slug,
      seo,
      illustration: { illustrationUrl },
    },
    otherCategories: { otherCategoriesNodes },
  },
  pageContext,
}) => {
  // PROPS
  const {
    seoTitle,
    seoDescription
  } = seo || {};
  const breadcrumbRef = useRef(null);

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  const { locale } = pageContext;

  const scrollOnRefine = () => {
    breadcrumbRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  if (!isSSR) smoothscroll.polyfill();

  return (
    <PageWrapper
      pageData={pageContext}
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      // seoImage={seoImageUrl}
    >
      <CategoryHero
        backgroundImageData={gatsbyImageData}
        categoryTitle={title}
        illustrationUrl={illustrationUrl}
        categoryDescription={description}
      />
      <InstantSearch indexName={PRODUCTS_INDEX_NAME} searchClient={searchClient}>
        <FakeBreadCrumb categoryTitle={title} breadcrumbRef={breadcrumbRef} />
        <Configure
          hitsPerPage={9}
          filters={`locale:${locale} AND (productCategory.slug:${slug} OR productCategoryAlias.slug:${slug})`}
        />
        <ProductsPanel setScroll={scrollOnRefine} />
      </InstantSearch>
      <CategoriesStream categoriesArray={otherCategoriesNodes} />
    </PageWrapper>
  );
};

export default CategoryTemplate;

export const query = graphql`
  query categoryQuery($id: String!, $locale: String!) {
    category: datoCmsCategory(
      locale: { eq: $locale }
      originalId: { eq: $id }
    ) {
      id: originalId
      title
      slug
      description
      illustration {
        illustrationUrl: url
      }
      seo {
        seoTitle: title
        seoDescription: description
        image {
          seoImageUrl: url
        }
      }
      heroImage {
        gatsbyImageData(placeholder: NONE)
      }
    }
    otherCategories: allDatoCmsCategory(
      sort: { order: ASC, fields: position }
      filter: { locale: { eq: $locale }, originalId: { ne: $id } }
    ) {
      otherCategoriesNodes: nodes {
        comingSoon
        originalId
        title
        slug
        thumbnail {
          gatsbyImageData(placeholder: NONE)
        }
      }
    }
    products: allDatoCmsProdotti(
      filter: {
        locale: { eq: $locale }
        productCategory: { originalId: { eq: $id } }
      }
    ) {
      nodes {
        mainImage {
          gatsbyImageData(placeholder: NONE)
          alt
        }
        titolo
        slug
        regimeAlimentare
        productCategory {
          originalId
          title
          slug
        }
      }
    }
  }
`;
