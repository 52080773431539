import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { useIntl } from 'react-intl';
import { Navigator } from '../../LanguageHelpers/Navigator';
import { easeInOutSine } from '../../styles/animations';
import { HeadingSection } from '../../styles/Typography';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  padding: var(--gap-2) var(--padding) var(--wave-offset-padding) var(--padding);
  background: var(--gold-3);
  border-top: 10px solid var(--gold-3);
  align-items: center;
  margin-bottom: var(--wave-offset-negative-margin);
`;

const Container = styled.div`
  width: 1000px;
  display: grid;
  justify-content: center;
  row-gap: var(--gap-2);

  & h1 {
    text-align: center;
  }
`;

const CategoriesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: var(--gap-3);
  column-gap: var(--gap-4);

  @media screen and (max-width: 910px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 768px) {
    column-gap: var(--gap-5);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 350px) {
    grid-template-columns: 1fr;
  }
`;

const CategoryCard = styled(Navigator)`
  display: grid;
  row-gap: var(--gap-6);
  justify-items: center;
  transition: transform 200ms ${easeInOutSine};
  position: relative;
  grid-template-rows: repeat(2, min-content);

  ${({ $comingSoon }) =>
    $comingSoon &&
    css`
    cursor: not-allowed;

    &:hover {
      transform: unset !important;
    }
  '0.5'`};

  @media (hover: hover) {
    &:hover {
      transform: translateY(-5px);
    }
  }
`;

const CategoryImage = styled(GatsbyImage)`
  opacity: ${({ $comingSoon }) => $comingSoon && '0.6'};
`;

const CategoryTitle = styled.h1`
  color: var(--gold-2);
  font-size: var(--font-size);
  line-height: 1.2;
`;

const ComingSoon = styled.span`
  color: white;
  background: var(--gold-2);
  position: absolute;
  top: 1em;
  right: 1em;
  z-index: 2;
  padding: 0 0.4em;
  font-weight: 700;
`;

export const CategoriesStream = ({ categoriesArray }) => {
  const intl = useIntl();

  return (
    <Wrapper>
      <Container>
        <HeadingSection goldHeading fullWidth>
          {intl.formatMessage({ id: 'productsCategory' })}
        </HeadingSection>
        <CategoriesGrid>
          {categoriesArray.map(
            ({
              originalId,
              title,
              thumbnail: { gatsbyImageData },
              comingSoon,
            }) => (
              <CategoryCard
                $comingSoon={comingSoon}
                as={comingSoon && 'div'}
                key={originalId}
                recordId={originalId}
              >
                {comingSoon && (
                  <ComingSoon>
                    {intl.formatMessage({ id: 'comingSoon' })}
                  </ComingSoon>
                )}
                <CategoryImage
                  image={gatsbyImageData}
                  alt={title}
                  $comingSoon={comingSoon}
                />
                <CategoryTitle>{title}</CategoryTitle>
              </CategoryCard>
            )
          )}
        </CategoriesGrid>
      </Container>
    </Wrapper>
  );
};
