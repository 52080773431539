import { connectInfiniteHits } from 'react-instantsearch-dom';
import styled from 'styled-components';
import { useIntl } from 'react-intl';

const Counter = styled.div`
  font-size: var(--font-size-micro-1);
  color: var(--blue-2);
`;

const Hits = ({ hits }) => {
  const intl = useIntl();

  return (
    <Counter>
      {hits.length >= 1 ? (
        <span>
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <span>{hits.length}</span>
          {intl.formatMessage({ id: 'results' })}
        </span>
      ) : (
        <span>{intl.formatMessage({ id: 'noResults' })}</span>
      )}
    </Counter>
  );
};

export const ResultsCounter = connectInfiniteHits(Hits);
