export const ResetIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20.001"
    viewBox="0 0 24 20.001"
  >
    <g transform="translate(0 0)">
      <path d="M20.824,12.061a1,1,0,0,0-1.276.608,8.079,8.079,0,0,1-1.887,2.991A7.953,7.953,0,0,1,12.005,18h0a7.973,7.973,0,0,1-5.679-2.366L3.524,13H7a1,1,0,0,0,0-2H1l-.015,0a.985.985,0,0,0-.249.051.986.986,0,0,0-.133.03,1.152,1.152,0,0,0-.106.071,1.023,1.023,0,0,0-.214.152s-.008,0-.012.009a.894.894,0,0,0-.07.114.98.98,0,0,0-.127.2,1,1,0,0,0-.046.224A.99.99,0,0,0,0,12v6a1,1,0,0,0,2,0V14.315l2.932,2.756A9.943,9.943,0,0,0,12,20h0a9.938,9.938,0,0,0,7.07-2.927,10.061,10.061,0,0,0,2.357-3.737,1,1,0,0,0-.608-1.276" />
      <path d="M23.972,8.144A.951.951,0,0,0,24,8V2a1,1,0,0,0-2,0V5.692L19.068,2.936A10.076,10.076,0,0,0,15.331.578,10,10,0,0,0,2.567,6.67a1,1,0,0,0,1.886.667,8,8,0,0,1,10.21-4.873,8.1,8.1,0,0,1,3.013,1.908L20.476,7H17a1,1,0,0,0,0,2h6a1,1,0,0,0,.391-.079l.009-.007a.979.979,0,0,0,.317-.221l.012-.008a.912.912,0,0,0,.071-.115.969.969,0,0,0,.126-.2.965.965,0,0,0,.046-.226" />
    </g>
  </svg>
);
