export const FilterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="20.001"
    viewBox="0 0 24 20.001"
  >
    <path
      d="M4,9A1,1,0,0,0,5,8V1A1,1,0,0,0,3,1V8A1,1,0,0,0,4,9"
      fill="#040405"
    />
    <path
      d="M12,9a1,1,0,0,0-1,1v9a1,1,0,0,0,2,0V10a1,1,0,0,0-1-1"
      fill="#040405"
    />
    <path
      d="M20,11a1,1,0,0,0,1-1V1a1,1,0,1,0-2,0v9a1,1,0,0,0,1,1"
      fill="#040405"
    />
    <path
      d="M7,11H1a1,1,0,0,0,0,2H3v6a1,1,0,0,0,2,0V13H7a1,1,0,0,0,0-2"
      fill="#040405"
    />
    <path
      d="M15,5H13V1a1,1,0,0,0-2,0V5H9A1,1,0,0,0,9,7h6a1,1,0,0,0,0-2"
      fill="#040405"
    />
    <path
      d="M23,13H17a1,1,0,0,0,0,2h2v4a1,1,0,1,0,2,0V15h2a1,1,0,0,0,0-2"
      fill="#040405"
    />
  </svg>
);
