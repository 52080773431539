import { useState, useEffect, useRef } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { connectInfiniteHits } from 'react-instantsearch-dom';
import { easeInOutSine, fadeInZoomAnimMin } from '../../styles/animations';
import { Navigator } from '../../LanguageHelpers/Navigator';
import { usePageLocale } from '../../../hooks/usePageLocale';

const Wrapper = styled.ol`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--gap-3);
  row-gap: var(--gap-4);
  list-style-type: none;
  margin: 0;
  padding: 0;

  @media screen and (max-width: 910px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: repeat(3, minmax(120px, 180px));
  }

  @media screen and (max-width: 480px) {
    grid-template-columns: repeat(2, minmax(120px, 1fr));
  }

  @media screen and (max-width: 320px) {
    grid-template-columns: 1fr;
  }
`;

const ProductCard = styled.article`
  transition: transform 150ms ease-out;
  cursor: pointer;
  ${({ removeAnimation }) =>
    !removeAnimation &&
    css`
      animation: ${fadeInZoomAnimMin} 200ms ${easeInOutSine} forwards;
    `};

  &:hover {
    transform: translateY(-10px);
  }
`;

const ProductImage = styled(GatsbyImage)`
  width: 160px;
  height: 160px;

  @media screen and (max-width: 640px) {
    width: 140px;
    height: 140px;
  }

  @media screen and (max-width: 350px) {
    width: 120px;
    height: 120px;
  }
`;

const ProductLink = styled(Navigator)`
  display: grid;
  row-gap: var(--gap-6);
  justify-items: center;
`;

const ProductTitle = styled.h1`
  color: var(--blue-2);
  text-align: center;
  max-width: 220px;
  line-height: 1.3;
`;

const Hits = ({ hits, hasMore, refineNext }) => {
  const { locale } = usePageLocale();
  const sentinel = useRef(null);
  const [removeAnimation, setRemoveAnimation] = useState(false);

  const onSentinelIntersection = (entries) => {
    entries.forEach(({ isIntersecting }) => {
      if (isIntersecting && hasMore) {
        refineNext();
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(onSentinelIntersection);
    observer.observe(sentinel.current);

    return () => observer.disconnect();
  });

  return (
    <Wrapper>
      {hits.map(
        ({ objectID, titolo, mainImage: { cardImageData }, fakeObjectID }) => (
          <li key={objectID}>
            <ProductCard
              removeAnimation={removeAnimation}
              onAnimationEnd={() => setRemoveAnimation(true)}
            >
              <ProductLink
                recordId={fakeObjectID ?? objectID.replace(`_${locale}`, '')}
                aria-label={titolo}
              >
                <ProductImage image={cardImageData} alt={titolo} />
                <ProductTitle>{titolo}</ProductTitle>
              </ProductLink>
            </ProductCard>
          </li>
        )
      )}
      <div ref={sentinel} />
    </Wrapper>
  );
};

export const ProductHits = connectInfiniteHits(Hits);
