import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { useIntl } from 'react-intl';
import { CloseRefinements, CustomClearRefinements } from './ProductsClearRefinement';
import { ProductHits } from './ProductsHitsStream';
import { ProductsRefinementList } from './ProductsRefinementList';
import {
  easeInOutSine,
  fadeInZoomAnim,
  fadeOutZoomAnim,
  unMountAfterAnim,
} from '../../styles/animations';
import { FilterIcon } from '../../vectors/Filter';

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--padding) 80px var(--padding);
`;

const Container = styled.div`
  width: var(--layout-desktop-container);
  display: grid;
  grid-template-columns: 1fr 3fr;
  position: relative;

  @media screen and (max-width: 910px) {
    grid-template-columns: 1fr 2fr;
  }

  @media screen and (max-width: 640px) {
    grid-template-columns: 1fr;
  }
`;

const Left = styled.aside`
  align-self: start;
  position: sticky;
  top: var(--gap-4);
  display: grid;
  row-gap: var(--gap-4);

  @media screen and (max-width: 640px) {
    max-width: calc(100vw - 60px);
    visibility: ${({ showFilters }) => showFilters === null && 'hidden'};
    animation: ${({ showFilters }) =>
        showFilters === true
          ? fadeInZoomAnim
          : showFilters === false && fadeOutZoomAnim}
      300ms ${easeInOutSine} forwards;
    position: fixed;
    z-index: 2;
    top: unset;
    bottom: var(--gap-4);
    right: var(--gap-4);
    background: var(--background-white);
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(1, 16, 39, 0.15);
    padding: 25px;
    overflow: scroll;
    max-height: calc(100vh - calc(var(--gap-4) * 2));
  }
`;
const OpenFiltersWrapper = styled.aside`
  width: 100%;
  padding: var(--gap-4);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: var(--gap-4);
  z-index: 5;
  left: 0;
  pointer-events: ${({ showFilters }) => showFilters && 'none'};
`;

const OpenFilters = styled.button`
  margin: 0;
  background: var(--blue-2);
  color: white;
  font-weight: 500;
  padding: var(--gap-6);
  font-size: var(--font-size-2);
  border: 2px solid white;
  border-radius: 5px;
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  column-gap: var(--gap-7);

  & svg {
    width: 16px;
    height: 16px;

    & path {
      fill: white;
    }
  }

  @media screen and (min-width: 641px) {
    display: none;
  }
`;

const ControlHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: -15px;
`;

const Right = styled.section`
  margin-top: var(--gap-4);
`;

export const ProductsPanel = ({ setScroll }) => {
  const [showFilters, setShowFilters] = useState(null);

  const [inViewRef, inView] = useInView();
  const intl = useIntl();

  useEffect(() => {
    if (showFilters) {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
    }
    return () => {
      document.body.style.overflow = 'auto';
      document.documentElement.style.overflow = 'auto';
    };
  }, [showFilters]);

  return (
    <Wrapper>
      <Container ref={inViewRef}>
        <Left
          setShowFilters={setShowFilters}
          showFilters={showFilters}
          onAnimationEnd={() => unMountAfterAnim(showFilters, setShowFilters)}
        >
          <ControlHeader>
            <CustomClearRefinements
              setShowFilters={setShowFilters}
              scrollOnRefine={setScroll}
            />
            <CloseRefinements onClick={() => setShowFilters(!showFilters)} />
          </ControlHeader>
          <ProductsRefinementList
            scrollOnRefine={setScroll}
            filterTitle={intl.formatMessage({ id: 'category' })}
            attribute="subcategory"
          />
          <ProductsRefinementList
            scrollOnRefine={setScroll}
            filterTitle={intl.formatMessage({ id: 'diet' })}
            attribute="regimiAlimentari"
          />
          <ProductsRefinementList
            scrollOnRefine={setScroll}
            filterTitle={intl.formatMessage({ id: 'timesOfDay' })}
            attribute="momenti"
          />
        </Left>
        <Right>
          <ProductHits minHitsPerPage={9} />
        </Right>
        <OpenFiltersWrapper showFilters={showFilters}>
          {!showFilters && inView && (
            <OpenFilters onClick={() => setShowFilters(!showFilters)}>
              <FilterIcon />
              {intl.formatMessage({ id: 'filters' })}
            </OpenFilters>
          )}
        </OpenFiltersWrapper>
      </Container>
    </Wrapper>
  );
};
